import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

import quoteImg from "assets/images/icons/quoteSB.svg";
import cake from "assets/images/birthday/cake.png";
import hanucaBlue from "assets/images/holliday/hanucaBlue.png";
import independanceBlue from "assets/images/holliday/independanceBlue.png";
import pesahBlue from "assets/images/holliday/pesahBlue.png";
import purimBlue from "assets/images/holliday/purimBlue.png";
import shavuotBlue from "assets/images/holliday/shavuotBlue.png";
import sucotBlue from "assets/images/holliday/sucotBlue.png";
import yearBlue from "assets/images/holliday/yearBlue.png";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import SendTemplateMessagePopover from "./SendTemplateMessagePopover";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import { _ } from "lodash";
import { getChat, getIsNewChat } from "redux/chatReceived/selectors";
import { getCountMessagesLimit, getIsUserSubscribed } from "redux/chatMessages/selectors";

import { fetchChatMessages, sendChatMessage } from "redux/chatMessages/actions";
import localStorage from "services/localStorage";
import ChatMessagesService from "services/chatMessages";
import { orderBy } from "lodash";
import { checkRelevantQuota } from "helpers/quota";
import { getUserQuotaStatus } from "redux/general/selectors";
import chatRequestsService from "services/chatRequestsService";
import { useCanUserSendMsgByAge } from "hooks/useCanUserSendMsgByAge";

export default ({
	user,
	usePopover = false,
	className = "",
	isFromCardUserSidebar,
	effortBtn = "",
	templates = [],
	sentEffort = () => {},
}) => {
	const dispatch = useDispatch();
	const currentUser = useSelector(getUserProfile);
	const { approved, readPopupExplanationEffort } = currentUser;
	const {
		showSendTemplateMessagePopup,
		showNotApprovedMessagePopup,
		showSubscriberLimitMessagesPopup,
		showNonSubscriberLimitMessagesPopup,
		showEffortSuccessfullyPopup,
		showExplanationEffortPopup,
		showQuotaPopup,
		showFittedMessagePopup,
		showCanSendMsgAgePopup,
	} = usePopups();
	const chatID = useSelector(getChat(user.uid));
	const countLimit = useSelector(getCountMessagesLimit);
	const isNewChat = useSelector(getIsNewChat(user.uid));
	const isUserSubscribed = useSelector(getIsUserSubscribed);
	const userQuotaStatus = useSelector(getUserQuotaStatus);
	const canSendMessage = useCanUserSendMsgByAge(user);

	const handleSendMessage = async (message, classImg = null, title) => {
		if (!isNewChat) {
			const res = await chatRequestsService.getCanSendMsg(chatID);
			if (!res?.data || !res?.data?.success || !res?.data?.canSend) {
				if (res?.data?.openPopup)
					return showFittedMessagePopup(
						"היי, לא ניתן לשלוח הודעה נוספת לפני שהצד השני מאשר את השיחה.",
						false
					);
				return;
			}
		}
		getCanSendMessageToUser().then((canSendMsg) => {
			if (!!canSendMsg) {
				sentEffort(true);
				setUserInCookie(title, user.uid);
				dispatch(sendChatMessage(chatID, message, user.uid, isNewChat, false, true, classImg));
				showEffortSuccessfullyPopup(title);
			} else {
				isUserSubscribed && showSubscriberLimitMessagesPopup();
				!isUserSubscribed && showNonSubscriberLimitMessagesPopup();
			}
		});
	};

	const getCanSendMessageToUser = async () => {
		if (!chatID) return true;
		const messages = await ChatMessagesService.fetchChatMessages(chatID);
		let stopLoop = false;
		const messagesDesc = orderBy(messages, "timeStamp", "desc");
		const lengthMsg = messagesDesc.filter((message, i) => {
			if (stopLoop) return false;
			message.uid !== currentUser.uid && (stopLoop = true);
			return (
				message.uid === currentUser.uid && (i === 0 || message.uid === messagesDesc[i - 1].uid)
			);
		}).length;

		return lengthMsg < countLimit;
	};

	const handleOnClick = async () => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();
		if (!canSendMessage) return showCanSendMsgAgePopup(user);

		if (approved) {
			showSendTemplateMessagePopup(user, isFromCardUserSidebar);
		} else {
			showNotApprovedMessagePopup();
		}
	};

	const handleOnClickBirthOrHoliday = async (title) => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();

		if (!chatID) dispatch(fetchChatMessages(user.uid, chatID));

		if (approved) {
			if (!canSendMessage) return showCanSendMsgAgePopup(user);
			if (!readPopupExplanationEffort) return showExplanationEffortPopup(user);
			const randomTemplate = getRandomTemplateByTitle(title);
			if (!randomTemplate) return;

			const msg = !!user.gender ? randomTemplate.messageFemale : randomTemplate.messageMale;
			const msgToSent = msg.replace("[name]", user?.name);
			if (!!randomTemplate) {
				handleSendMessage(msgToSent, randomTemplate.image, randomTemplate.title);
			}
		} else {
			showNotApprovedMessagePopup();
		}
	};

	const getRandomTemplateByTitle = (title) => {
		const filteredTemplates = templates.filter((template) => template.title === title);
		if (filteredTemplates.length === 0) {
			console.log(`No templates found with title ${title}`);
			return null;
		}
		const randomIndex = Math.floor(Math.random() * filteredTemplates.length);
		return filteredTemplates[randomIndex];
	};

	const setUserInCookie = (title, uid) => {
		const storageList = localStorage.get(title);
		if (!storageList?.length) {
			if (title == "Birthday") return localStorage.set(title, JSON.stringify([uid]), 7);
			localStorage.set(title, JSON.stringify([uid]), 30);
		} else {
			const pharseList = JSON.parse(storageList);
			if (pharseList.includes(uid)) return;

			if (title == "Birthday")
				return localStorage.set(title, JSON.stringify([...pharseList, uid]), 7);
			localStorage.set(title, JSON.stringify([...pharseList, uid]), 30);
		}
	};

	const setIsUserInStore = () => {
		if (!!getIfUserBirtdayInThisWeek(user?.dob)) {
			const storageList = localStorage.get("Birthday");
			if (!storageList) return false;
			const pharseList = JSON.parse(storageList);
			return !!pharseList.includes(user?.uid);
		} else if (!!effortBtn) {
			const storageList = localStorage.get(effortBtn);
			if (!storageList) return false;
			const pharseList = JSON.parse(storageList);
			return !!pharseList.includes(user?.uid);
		}
		return false;
	};
	return (
		<>
			{usePopover && <SendTemplateMessagePopover user={user} className={className} />}
			{!!isFromCardUserSidebar || !!setIsUserInStore() ? (
				<>
					<Button
						id="qouteTrig"
						{...(!usePopover && { onClick: handleOnClick })}
						outline
						className="dislike">
						<img src={quoteImg} />
					</Button>
				</>
			) : (
				<>
					{!!getIfUserBirtdayInThisWeek(user?.dob) ? (
						<Button
							id="qouteTrig"
							{...(!usePopover && { onClick: () => handleOnClickBirthOrHoliday("Birthday") })}
							outline
							className="cake">
							<img src={cake} style={{ height: "27px", width: "25px" }} />
						</Button>
					) : (
						<>
							{!!effortBtn ? (
								<>
									<EffBtn effortBtn={effortBtn} handleClickHoliday={handleOnClickBirthOrHoliday} />
								</>
							) : (
								<>
									<Button
										id="qouteTrig"
										{...(!usePopover && { onClick: handleOnClick })}
										outline
										className="dislike">
										<img src={quoteImg} />
									</Button>
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

const EffBtn = ({ effortBtn, handleClickHoliday }) => {
	const handleClick = (type) => {
		handleClickHoliday(type);
	};
	const getBtn = () => {
		{
			switch (effortBtn) {
				case "Purim":
					return (
						<>
							<Button id="qouteTrig" onClick={() => handleClick("Purim")} outline className="Purim">
								<img src={purimBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				case "Pesah":
					return (
						<>
							<Button id="qouteTrig" onClick={() => handleClick("Pesah")} outline className="Pesah">
								<img src={pesahBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				case "Shavuot":
					return (
						<>
							<Button
								id="qouteTrig"
								onClick={() => handleClick("Shavuot")}
								outline
								className="Shavuot">
								<img src={shavuotBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				case "Independence":
					return (
						<>
							<Button
								id="qouteTrig"
								onClick={() => handleClick("Independence")}
								outline
								className="Independence">
								<img src={independanceBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				case "Year":
					return (
						<>
							<Button id="qouteTrig" onClick={() => handleClick("Year")} outline className="Year">
								<img src={yearBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				case "Hanuca":
					return (
						<>
							<Button
								id="qouteTrig"
								onClick={() => handleClick("Hanuca")}
								outline
								className="Hanuca">
								<img src={hanucaBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				case "Sucot":
					return (
						<>
							<Button id="qouteTrig" onClick={() => handleClick("Sucot")} outline className="Sucot">
								<img src={sucotBlue} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
				default:
					return (
						<>
							<Button id="qouteTrig" onClick={() => () => {}} outline className="value">
								<img src={quoteImg} style={{ height: "27px", width: "25px" }} />
							</Button>
						</>
					);
			}
		}
	};

	return <>{getBtn()}</>;
};
