import firebase from "firebase/app";
import "firebase/database";
import httpsCallable from "./httpsCallable";
import { Observable } from "rxjs";
import moment from "moment";
import { getAgeByDob } from "helpers/date";
import localStorage from "./localStorage";

class SpeedDatingService {
	SPEED_DATE_USERS = "speed_dating_users";
	SPEED_DATE_MATCH = "speed_dating_match";
	SPEED_DATE_EVENTS = "speed_dating_events";
	SPEED_DATE_EVENTS_USERS = "speed_dating_events_users";
	SPEED_DATE_DATE = "speed_dating_date";
	SPEED_DATE_EVENTS_STATISTICS = "speed_dating_events_statistics";
	SPEED_DATE_FEEDBACK = "speed_dating_feedback";
	SPEED_DATE_FEEDBACK_RESULT = "speed_dating_feedback_result";
	USERS_SPEED_DATING_EVENTS = "users_speed_dating_events";
	SPEED_DATE_DATE_LOG = "speed_dating_date_log";

	validateManualRating = (ratingData = [], userRating) => {
		if (!ratingData?.length) return true;
		if (ratingData.includes(3) && !userRating) return true;
		return ratingData.includes(userRating);
	};

	checkIfUserCanRegisterToEventByFilters(speedData, currentUser) {
		if (speedData?.stance && speedData?.stance?.length) {
			if (!speedData?.stance.includes(currentUser?.stance)) return false;
		}
		if (speedData?.maritalStatus && speedData?.maritalStatus?.length) {
			if (!speedData?.maritalStatus.includes(currentUser?.maritalStatus)) return false;
		}
		// if (speedData?.manualRating && speedData?.manualRating?.length) {
		// 	let checkManual = true;
		// 	if (speedData?.manualRating.includes(3) && !currentUser?.manualRating) checkManual = false;
		// 	if (checkManual && !speedData?.manualRating.includes(currentUser?.manualRating)) return false;
		// }

		const isValidRating = currentUser?.gender
			? this.validateManualRating(speedData?.manualRatingFemale, currentUser?.manualRating)
			: this.validateManualRating(speedData?.manualRatingMale, currentUser?.manualRating);

		if (!isValidRating) return false;

		const age = getAgeByDob(currentUser.dob);
		let filterAge;
		if (currentUser.gender == 1) {
			filterAge = speedData?.femaleFilters;
		} else {
			filterAge = speedData?.maleFilters;
		}

		if (filterAge.fromAge <= age && filterAge.toAge >= age) {
			return true;
		}
		return false;
	}

	async getTestRoom(speedDatingKey) {
		const result = await httpsCallable.call("getTestRoom", {
			speedDatingKey,
		});
		return result;
	}
	async getMyDating(speedDatingKey) {
		const result = await httpsCallable.call("getMyDates", {
			speedDatingKey,
		});
		return result;
	}

	async getChatVideoRoom(speedDatingKey, uidDate) {
		const result = await httpsCallable.call("getChatVideoRoom", {
			speedDatingKey,
			uidDate,
		});
		return result;
	}
	async registerUserToSpeedDating(speedDatingKey) {
		const result = await httpsCallable.call("registerUserToSpeedDating", {
			speedDatingKey,
			sourceSale: this.getSourceSale(),
		});
		return result;
	}
	async confirmUserSpeedDating(speedDatingKey) {
		const result = await httpsCallable.call("confirmUserSpeedDating", {
			speedDatingKey,
		});
		return result;
	}
	async cancelUserSpeedDating(speedDatingKey) {
		const result = await httpsCallable.call("cancelUserSpeedDating", {
			speedDatingKey,
		});
		return result;
	}

	getSourceSale() {
		return localStorage.get("sourceSale") || null;
	}
	async getSpeedDatingPaymentUrl(speedDatingKey) {
		const result = await httpsCallable.call("getSpeedDatingPaymentUrl", {
			speedDatingKey,
			sourceSale: this.getSourceSale(),
		});
		return result;
	}

	async reportSpeedDateUser(uidDate, message = "", email = null, speedDatingKey = "") {
		if (!uidDate) return;
		const result = await httpsCallable.call("reportSpeedDateUser", {
			uidDate,
			message,
			email,
			speedDatingKey,
		});
		return result;
	}

	async setUserFeedback({ speedDatingKey, feedbackKey, uidDate, value }) {
		const result = await httpsCallable.call("setUserFeedback", {
			speedDatingKey,
			feedbackKey,
			uidDate,
			value,
		});
		return result;
	}

	async getFeedbackQuestions() {
		return (await firebase.database().ref(this.SPEED_DATE_FEEDBACK).once("value")).val();
	}
	async getUserEvents(uid) {
		return (
			await firebase.database().ref(this.USERS_SPEED_DATING_EVENTS).child(uid).once("value")
		).val();
	}
	async getUserFeedback(speedKey, uid) {
		return (
			await firebase
				.database()
				.ref(this.SPEED_DATE_FEEDBACK_RESULT)
				.child(speedKey)
				.child(uid)
				.once("value")
		).val();
	}

	async getSpeedDateInfoByUrlName(urlName) {
		return (
			await firebase
				.database()
				.ref(this.SPEED_DATE_EVENTS)
				.orderByChild("urlName")
				.equalTo(urlName)
				.once("value")
		).val();
	}

	listenToUserInSpeedDate$(id, uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.SPEED_DATE_EVENTS_USERS).child(id).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	listenToUserDateLog$(speedDateKey, uid, colleagueUid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.SPEED_DATE_DATE_LOG)
				.child(speedDateKey)
				.child(uid)
				.child(colleagueUid);

			let lastTimestamp = Date.now();

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => {
					const data = snapshot.val();

					if (data && data?.updated > lastTimestamp) {
						lastTimestamp = data.updated;
						subscriber.next(data);
					}
				},
				(error) => subscriber.error(error)
			);

			return () => ref.off("child_added", callbackFn);
		});
	}

	async getSpeedDateInfo(id) {
		return (await firebase.database().ref(this.SPEED_DATE_EVENTS).child(id).once("value")).val();
	}
	async getNextSpeedDate() {
		return (
			await firebase
				.database()
				.ref(this.SPEED_DATE_EVENTS)
				.orderByChild("eventDate")
				.startAt(moment().subtract(2, "hours").valueOf())
				.once("value")
		).val();
	}
	async getUserInSpeedDate(id, uid) {
		if (!id || !uid) return;
		return (
			await firebase.database().ref(this.SPEED_DATE_EVENTS_USERS).child(id).child(uid).once("value")
		).val();
	}

	async getUserInSpeedDateNotInStartStatus(id, uid) {
		if (!id || !uid) return;

		const data = (
			await firebase.database().ref(this.SPEED_DATE_EVENTS_USERS).child(id).child(uid).once("value")
		).val();

		if (data && data.status !== "start") {
			return data;
		} else return null;
	}
}

export default new SpeedDatingService();
