export default {
	strip: "strip",
	chatBtn: "chatBtn",
	joinNowButton: "joinNowButton",
	bannerProfile: "bannerProfile",
	bannerChat: "bannerChat",
	purchasePage: "purchasePage",
	matchPagePopup: "matchPagePopup",
	limitMsgPopup: "limitMsgPopup",
	blurMsgPopup: "blurMsgPopup",
	matchAiPopup: "matchAiPopup",
	nonSubscribeCantSendMsgPopup: "nonSubscribeCantSendMsgPopup",
	nonSubscribeCantSendMsgAgePopup: "nonSubscribeCantSendMsgAgePopup",
	nonSubscribeToNonSubPopup: "nonSubscribeToNonSubPopup",
	nonSubscribeToSubPopup: "nonSubscribeToSubPopup",
	subscribeToSpeedDatePopup: "subscribeToSpeedDatePopup",
	accountNav: "accountNav",
};
