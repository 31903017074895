import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import usePopups from "hooks/usePopups";

export default function MatchBetweenUsersAi({ uidPartner, partner = {}, currentUser = {} }) {
	const { showMatchBetweenUsersAiPopup, showApproveAiPopup, closePopup } = usePopups();

	const handleShowMatch = () => {
		if (!currentUser?.approveAi) return showApproveAiPopup();
		if (currentUser?.approveAi && partner?.approveAi)
			return showMatchBetweenUsersAiPopup(uidPartner);
		return closePopup();
	};

	return (
		<div className="userDetailsCollapse">
			<div className="wrapTitleOFCollapse" style={{ position: "relative" }}>
				<span
					style={{
						position: "absolute",
						top: "-15px",
						fontWeight: "500",
						fontSize: "12px",
						background: "#F65368",
						borderRadius: "20px",
						padding: "2px 3px",
						right: "-20px",
						zIndex: "1",
						color: "#fff",
						transform: "rotate(20deg)",
					}}>
					חדש!
				</span>
				<h4 className="text-left text-primary font-size-16">{"האם אתם מתאימים?"}</h4>
			</div>
			<div
				className="wrapSumBtn collapseMe"
				style={{ justifyContent: "start", position: "relative", width: "fit-content" }}>
				{currentUser?.approveAi && !partner?.approveAi ? (
					<span>{`פיצ'ר הAi החדש שלנו בהרצה במתכונת מצומצמת, ויעלה בקרוב באופן מלא עבור כולם!`}</span>
				) : (
					<>
						<AutoAwesomeIcon style={{ color: "#2d5086" }} />
						<button
							className="btn-rounded tagStyle btn btn-outline-primary btn-sm"
							onClick={handleShowMatch}>
							{"לחצו וגלו מה הAi שלנו חושב..."}
						</button>
					</>
				)}
			</div>
		</div>
	);
}
