import i18next from "i18next";
import { useSelector } from "react-redux";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";

import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import localStorage from "services/localStorage";
import sourceSaleTypes from "consts/sourceSaleTypes";

export default () => {
	const currentUser = useSelector(getUserProfile);
	const { closePopup, showSubscriptionPopup } = usePopups();
	const { user, isLike } = useSelector(getPopups);

	const handleClick = () => {
		localStorage.set("sourceSale", sourceSaleTypes.nonSubscribeCantSendMsgAgePopup);
		showSubscriptionPopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog blueShlishDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>
			<ModalHeader>
				{`היי ${currentUser?.name || ""}, על מנת לפנות ל${user?.name || ""} עליך לרכוש מנוי.`}
			</ModalHeader>
			<ModalBody>
				<div>
					<b
						style={{
							display: "block",
							fontWeight: "normal",
						}}>{`שים לב מנויים מרוויחים הרבה יותר, ומגדילים בהרבה את הסיכוי למצוא את החצי השני.`}</b>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={handleClick}
					className="btn-rounded littleBtn">
					{`לרכישה מנוי ${currentUser?.gender ? "לחצי" : "לחץ"} כאן`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
