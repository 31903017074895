import useNavigator from "hooks/useNavigator";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { getUserProfile } from "redux/profile/selectors";
import usePopups from "hooks/usePopups";
import { CircularProgress } from "@mui/material";
import purchaseTypes from "consts/purchaseTypes";
import promotion from "services/promotion";
import localStorage from "services/localStorage";
import sourceSaleTypes from "consts/sourceSaleTypes";

export default function PurchasesPopups() {
	const { showPromotionsSalePopup, showSubscriptionPopup } = usePopups();
	const { uid } = useSelector(getUserProfile);
	const { type } = useParams();
	const navigator = useNavigator();

	const navigateToMatch = () => {
		return navigator.navigateToMatchesPage();
	};

	useEffect(async () => {
		if (!uid) {
			return setTimeout(() => {
				const currentPath = window.location.pathname;
				if ((currentPath || "").includes("purchases")) navigator.navigateToHomePage();
			}, 7000);
		}

		const purchTypes = Object.keys(purchaseTypes);
		if (!purchTypes.includes(type)) navigateToMatch();

		localStorage.set("sourceSale", sourceSaleTypes.purchasePage);
		switch (type) {
			case purchaseTypes.promotion:
				const promotionResult = await promotion.getActivePromotionsSalePlan();
				if (promotionResult) showPromotionsSalePopup(promotionResult);
				break;
			case purchaseTypes.subscription:
				setTimeout(() => {
					showSubscriptionPopup();
				}, 1000);
				break;
		}
		navigateToMatch();
	}, [uid]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100vw",
				height: "100vh",
			}}>
			<CircularProgress size={40} className="text-primary" />
		</Box>
	);
}
