import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-alice-carousel/lib/alice-carousel.css";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import promotion from "services/promotion";
import boost from "assets/images/newSubscription/boost.svg";
import sourceSaleTypes from "consts/sourceSaleTypes";
import localStorage from "services/localStorage";

export default () => {
	const { uid, gender } = useSelector(getUserProfile);

	const [promotionSalePlan, setPromotionSalePlan] = useState(null);

	const [canBuyPromotion, setCanBuyPromotion] = useState(false);
	const { showPromotionsSalePopup, showFittedMessagePopup } = usePopups();

	const handleClick = () => {
		if (!canBuyPromotion) {
			const text = (
				<div>
					<div>{`אופס. הכל לטובה..:)`}</div>
					<div>{`מכסת הקידומים נסגרה להיום.`}</div>
					<div>{`מבטיחים להגדיל את הכמות בהקדם ובהדרגה.`}</div>
					<div>{`${gender ? "נסי" : "נסה"} שנית מחר`}</div>
					<div>{`בהצלחה ❤️`}</div>
				</div>
			);

			showFittedMessagePopup(text, false);
		} else {
			localStorage.set("sourceSale", sourceSaleTypes.bannerProfile);
			showPromotionsSalePopup(promotionSalePlan);
		}
	};
	useEffect(async () => {
		if (!uid) return;
		const promotionResult = await promotion.getActivePromotionsSalePlan();
		if (!!promotionResult) setPromotionSalePlan(promotionResult);
	}, [uid]);
	useEffect(async () => {
		if (!!promotionSalePlan?.countPromotionSalePerDay) {
			const canBuyRes = await promotion.getCanBuyPromotion(promotionSalePlan?.key);
			// debugger;
			if (canBuyRes?.data?.success) {
				setCanBuyPromotion(!!canBuyRes?.data?.data);
			} else {
				setCanBuyPromotion(false);
			}
		}
	}, [promotionSalePlan]);

	return (
		<div className={""} onClick={handleClick}>
			{!!canBuyPromotion && <PromoteBox gender={gender}></PromoteBox>}
		</div>
	);
};

const PromoteBox = ({ gender, className = "" }) => {
	return (
		<div className={`blue-box-promote ${className}`}>
			<div class="right">
				<span class="text">
					{gender ? "את יכולה" : "אתה יכול"}
					<b> להטיס את הכרטיס שלך </b>
					עם 3 ימים של בוסט שיגביר את מספר החשיפות שלך למשתמשים רלוונטיים!
				</span>
				<button class="btn2">לרכישת בוסט</button>
			</div>
			<img src={boost} alt="Notification" />
		</div>
	);
};
