import firebase from "firebase/app";
import "firebase/functions";
import getCallTypes from "../consts/getCallTypes";
import localStorage from "./localStorage";
const functions = firebase.functions();

class SubscriptionCallService {
	call = async (type, data) => {
		const _data = { type, data };
		const result = await functions.httpsCallable("getCall")(_data);
		return result.data;
	};
	pay = async (planId) => {
		return await this.call(getCallTypes.subsription_pay, {
			planId,
			sourceSale: this.getSourceSale(),
		});
	};

	googleIAPTransaction = async (receipt, productId, renewable) => {
		return await this.call(getCallTypes.google_iap_transaction, {
			receipt,
			productId,
			renewable,
		});
	};

	getSale = async () => {
		return await this.call(getCallTypes.subsription_get_sale, {});
	};

	getCanRead = async () => {
		return await this.call(getCallTypes.checkUserReadSub, {});
	};
	getSourceSale() {
		return localStorage.get("sourceSale") || null;
	}
}

export default new SubscriptionCallService();
