import "firebase/database";
import { Observable } from "rxjs";
import httpsCallable from "./httpsCallable";
import localStorage from "./localStorage";
class TokenService {
	getSourceSale() {
		return localStorage.get("sourceSale") || null;
	}

	async payByUserWithCardcomToken(numOfPayments, planId) {
		const result = await httpsCallable.call("payByUserWithCardcomToken", {
			numOfPayments,
			planId,
			sourceSale: this.getSourceSale(),
		});
		return result;
	}
	async payPromotionWithCardcomToken(numOfPayments, planId) {
		const result = await httpsCallable.call("payPromotionWithCardcomToken", {
			numOfPayments,
			planId,
			sourceSale: this.getSourceSale(),
		});
		return result;
	}
	async payVideoDateWithToken(speedDatingKey) {
		const result = await httpsCallable.call("createSaleByToken", {
			speedDatingKey,
			sourceSale: this.getSourceSale(),
		});
		return result;
	}
	async getUserTokenData() {
		const result = await httpsCallable.call("getUserTokenData", {});
		return result;
	}
}

export default new TokenService();
