import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import usePopups from "hooks/usePopups";
import { purchaseWithCardcom, purchaseWithGoogle } from "redux/subscription/actions";
import {
	getCardComUrl,
	getFetchCardcomApiStatus,
	getSale,
	getSubscriptionPlans,
	getUserSubscriptionType,
} from "redux/subscription/selectors";
import IframeContainer from "./IframeContainer";
import { getUserProfile } from "redux/profile/selectors";
import { getGoogleApiStatus } from "redux/subscription/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { getPopups, isSale } from "redux/popups/selectors";
import tokenService from "services/token.service";
import "assets/scss/popups/buyWithToken.scss";
import { Box } from "@mui/material";
import whiteLogo from "assets/images/newLogo.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import subscriptionTypesConst from "consts/subscription";
import i18next from "i18next";
//import dealImage from "assets/images/newSubscription/winter-bg.jpg";
// import dealImage from "assets/images/newSubscription/purim.jpg";
// import dealImage from "assets/images/newSubscription/pesah.jpg";
import dealImage from "assets/images/newSubscription/matzot.png";
import chatImgFemale from "assets/images/newSubscription/chat.svg";
import chatImgMale from "assets/images/newSubscription/chatMale.svg";
import arrow from "assets/images/newSubscription/arrow.svg";

export default function SaleModal() {
	const ref = useRef(false);

	const fetchCardcomApiStatus = useSelector(getFetchCardcomApiStatus);
	const cardComUrl = useSelector(getCardComUrl);
	const googleApiStatus = useSelector(getGoogleApiStatus);
	const sale = useSelector(getSale);

	const [hasToken, setHasToken] = useState("");
	const [tokenData, setTokenData] = useState("");
	const [selectedPlan, setSelectedPlan] = useState();
	const [showIframe, setShowIframe] = useState(false);

	const loggedInUserProfile = useSelector(getUserProfile);
	const { cardcomClearing } = useSelector(getPopups);

	const dispatch = useDispatch();

	const { closePopup, showSubscriptionSuccessfullyPopup, showBuyWithTokenPopup } = usePopups();

	const onContinuePurchase = () => {
		if (hasToken) {
			closePopup();
			return showBuyWithTokenPopup({ tokenData, selectedPlan, withoutReturn: true });
		}

		if (selectedPlan?.google_only) {
			dispatch(purchaseWithGoogle(selectedPlan));
		} else {
			dispatch(purchaseWithCardcom(selectedPlan, showSubscriptionSuccessfullyPopup));
			setShowIframe(true);
		}
	};
	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	const getHeaderText = () => {
		const saleHeader = (sale?.content?.title || "").replace(
			"{userName}",
			loggedInUserProfile?.name ?? ""
		);
		const saleSubTitle1 = (sale?.content?.subTitle1 || "").replace(
			"{userName}",
			loggedInUserProfile?.name ?? ""
		);

		return (
			<div style={{ fontSize: "22px", lineHeight: "1.3" }}>
				<b style={{ fontSize: "26px", fontWeight: "bold" }}>{saleHeader}</b>
				<br />
				{saleSubTitle1}
				<b style={{ fontSize: "32px", marginTop: "30px", display: "block" }}>
					{`מנוי ל ${selectedPlan?.sub_long} ${i18next.t(
						`Subscription.Times.${selectedPlan?.sub_time}`
					)}`}
					<br />
					{`ב ${selectedPlan?.monthly_price} ש"ח לחודש בלבד`}
				</b>
			</div>
		);
	};
	const getBodyText = () => {
		const saleSubTitle2 = (sale?.content?.subTitle2 || "").replace(
			"{userName}",
			loggedInUserProfile?.name ?? ""
		);

		return (
			<div className="termsPurch" style={{ fontSize: "17px", marginTop: "30px" }}>
				{saleSubTitle2}
				<br />
				{`ההצעה בתוקף לזמן מוגבל בלבד ובכפוף`}
				<a className="underLine" onClick={openInNewTab}>
					{" לתקנון."}
				</a>
				<br />

				<br />
				<b
					style={{
						fontSize: "19px",
						display: "block",
					}}>{`סיפור אהבה גדול, יכול להתחיל מצעד אחד קטן 💘`}</b>
				<b
					style={{
						fontSize: "19px",
						display: "block",
					}}>{`שליש גן עדן - מחתנים הכי הרבה זוגות בישראל - בהתחייבות!`}</b>
			</div>
		);
	};

	const openTermInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	useEffect(() => {
		if (!!cardcomClearing?.selectedPlan) {
			dispatch(
				purchaseWithCardcom(cardcomClearing.selectedPlan, showSubscriptionSuccessfullyPopup)
			);
			setShowIframe(true);
		}
	}, [cardcomClearing]);

	useEffect(async () => {
		const res = await tokenService.getUserTokenData();
		setHasToken(!!res.data?.success);

		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);

	useEffect(async () => {
		if (!sale || !sale?.plans[0]) return;
		setSelectedPlan(sale?.plans[0]);
	}, [sale]);

	useEffect(() => {
		if (!ref.current) {
			ref.current = true;
		} else {
			if (googleApiStatus === ApiRequestStatus.SUCCESSFULLY) {
				closePopup();
			}
		}
	}, [googleApiStatus]);

	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className={showIframe ? "subscriptionDialog" : "simpleDialog buyWithToken"}>
			{!showIframe ? (
				<>
					<div className="bg-deal-sub">
						<Button color="none" type="button" className="nav-btn" id="user-profile-hide">
							<i className="ri-close-line" onClick={() => closePopup()}></i>
						</Button>

						<img className="phone-bg" src={dealImage} />
						<div className="blue-bg"></div>
						<div className="content">
							<div className="title">
								<h1>{sale?.label}</h1>
							</div>
							{!loggedInUserProfile.gender && <img className="chatImg" src={chatImgMale} />}
							{!!loggedInUserProfile.gender && <img className="chatImg" src={chatImgFemale} />}
							<div className="desc">
								<div className="line1">
									<b>
										{selectedPlan?.sub_long}
										<b style={{ fontSize: "37px" }}>{" חודשים"}</b>
									</b>
									{/* <div>במחיר מיוחד</div> */}
								</div>
								<div className="line2">
									<div className="only-text" style={{ fontWeight: "400", lineHeight: 1 }}>
										במחיר מיוחד
									</div>
									<b>
										<span>₪</span>
										{Math.round(selectedPlan?.monthly_price)}
										<span className="only-text"> לחודש</span>
										{/* {Math.round(selectedPlan?.monthly_price * selectedPlan?.sub_long)} */}
									</b>
									{/* <span className="only-text"> שח</span> */}
									{/* <div className="only-text">בלבד</div> */}
									{/* 									<b> לחודש</b>
									 */}
								</div>
							</div>

							{!!selectedPlan?.renewable && (
								<div className="terms">
									מנוי מתמשך בחיוב חודשי בסך {selectedPlan?.monthly_price} ש"ח מידי חודש. המחיר
									בהתחייבות למינימום {selectedPlan?.sub_long} חודשים. בתום תקופת ההתחייבות, ממשיך
									מידי חודש ב{selectedPlan?.monthly_price} ש"ח ללא הגבלת זמן. ההצעה לתוקף לזמן מוגבל
									בלבד ובכפוף
									<a href="#" onClick={openTermInNewTab}>
										{" "}
										לתקנון.
									</a>
								</div>
							)}

							{!selectedPlan?.renewable && (
								<div className="terms">
									המנוי אינו מתחדש. ההצעה בתוקף לזמן מוגבל ובכפוף
									<a href="#" onClick={openTermInNewTab}>
										{" "}
										לתקנון המנוי
									</a>
								</div>
							)}

							<button
								onClick={onContinuePurchase}
								disabled={isNil(googleApiStatus !== ApiRequestStatus.PENDING)}
								className="submit">
								{`${loggedInUserProfile.gender ? "המשיכי" : "המשך"} `}
								<img
									style={{ width: "auto", height: "auto", marginTop: "-2px", marginRight: "2px" }}
									src={arrow}></img>
							</button>
							{!!selectedPlan?.renewable && (
								<div className="sub-1day">
									ניתן לבטל את החידוש בכל רגע בהגדרות החשבון שלך. המנוי מאפשר פתיחת הודעות
									רטרואקטיבית וקריאת כל ההודעות במהלך תקופת המנוי.
								</div>
							)}

							{!selectedPlan?.renewable && (
								<div className="sub-1day">
									המנוי מאפשר פתיחת הודעות רטרואקטיבית וקריאת כל ההודעות במהלך תקופת המנוי.
								</div>
							)}
						</div>
					</div>
					{/* {googleApiStatus === ApiRequestStatus.PENDING && <Loader />}
					<div className="user-chat-nav">
						<Button
							color="none"
							type="button"
							onClick={closePopup}
							className="nav-btn"
							id="user-profile-hide">
							<ArrowBackIosNewIcon style={{ color: "#fff", transform: "rotate(180deg)" }} />
						</Button>
					</div>
					<Box className="colorHeader">
						<img src={whiteLogo} alt="" height={"50px"} />
					</Box>

					<ModalHeader style={{ marginTop: "20px" }}>{getHeaderText()}</ModalHeader>
					<ModalBody style={{ flex: "1", padding: "1.5rem" }}>{getBodyText()}</ModalBody>

					<ModalFooter style={{ display: "flex", flexDirection: "column", marginBottom: "56px" }}>
						<Button
							outline
							size="sm"
							color="primary"
							style={{ minWidth: "fit-content", padding: "8px 20px" }}
							onClick={onContinuePurchase}
							className="btn-rounded approvePurchesBtn"
							disabled={isNil(googleApiStatus !== ApiRequestStatus.PENDING)}>
							{`${loggedInUserProfile.gender ? "רכשי" : "רכוש"} עכשיו את המנוי במבצע!`}
						</Button>
					</ModalFooter> */}
				</>
			) : (
				<ModalBody>
					<IframeContainer
						apiStatus={fetchCardcomApiStatus}
						url={cardComUrl}
						onBack={() => setShowIframe(false)}
					/>
				</ModalBody>
			)}
		</Modal>
	);
}
