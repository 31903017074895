import usePopups from "hooks/usePopups";
import bell from "assets/images/newSubscription/bell.svg";
import localStorage from "services/localStorage";
import sourceSaleTypes from "consts/sourceSaleTypes";

export default ({
	userProfile,
	className = "",
	text = "",
	marginBottom = "",
	source = "profile",
}) => {
	const { showSubscriptionPopup } = usePopups();

	const handleClick = () => {
		localStorage.set(
			"sourceSale",
			source === "profile" ? sourceSaleTypes.bannerProfile : sourceSaleTypes.bannerChat
		);
		showSubscriptionPopup();
	};

	return (
		<div
			className={`blue-box-subscription ${className}`}
			style={{ marginBottom: marginBottom ?? null, animation: "initial" }}
			onClick={handleClick}>
			<div className="right">
				<span className="text">{text}</span>
				<button onClick={handleClick} className="btn2">
					{userProfile?.gender ? "שדרגי" : "שדרג"} למנוי שליש+
				</button>
			</div>
			<img src={bell} alt="Notification" />
		</div>
	);
};
