import { useEffect, useState } from "react";
import speedDatingService from "services/speedDatingService";
import { Box, CircularProgress, Rating, Tooltip, Typography } from "@mui/material";
import feedbackTypes from "consts/feedbackTypes";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import moment from "moment";
import speedDateStatus from "consts/speedDateStatus";

export default function Feedback({
	currentDate,
	feedbackQuestions,
	speedData,
	myFeedback = {},
	toggleDrawer,
	withoutReport = false,
}) {
	const [values, setValues] = useState(null);
	const { showReportVideoDatePopup } = usePopups();
	const isDisabled =
		speedData?.status !== speedDateStatus.matched ||
		moment(speedData.eventDate).add(2, "hours").isBefore(moment());

	const handleReportVideoDate = async () => {
		const data = {
			speedDatingKey: speedData.key,
			matchUser: { name: currentDate?.name, uid: currentDate?.uidMatch },
		};
		toggleDrawer(false);
		showReportVideoDatePopup(data);
	};
	const sendForm = async () => {
		if (!values || !speedData?.eventDate || isDisabled) return;

		const promises = [];

		Object.entries(values || {}).map(([key, value]) => {
			promises.push(
				speedDatingService.setUserFeedback({
					speedDatingKey: speedData.key,
					feedbackKey: key,
					uidDate: currentDate.uidMatch,
					value,
				})
			);
		});
		await Promise.all(promises).then((res) => {});
	};

	useEffect(() => {
		if (!values || !currentDate || !Object.values(values || {}).filter((item) => !!item)?.length)
			return;
		sendForm();
	}, [values]);

	return (
		<>
			{!!feedbackQuestions && !!feedbackQuestions?.length && (
				<>
					{feedbackQuestions.map((item, index) => (
						<FeedbackItem
							item={item}
							key={index}
							setValues={setValues}
							name={currentDate?.name}
							myFeedback={myFeedback[item?.key]}
							isDisabled={isDisabled}
						/>
					))}
				</>
			)}

			{!withoutReport && (
				<span style={{ padding: "0 0 20px" }} onClick={handleReportVideoDate} className="reportVd">
					{`משהו לא תקין? `}
					<u>{`לחיצה כאן`}</u>
					{` תדווח על ${currentDate.name}`}
				</span>
			)}
		</>
	);
}

const FeedbackItem = ({ item, setValues, name = "", myFeedback = {}, isDisabled = false }) => {
	const [value, setValue] = useState(myFeedback?.value ?? null);

	const customIcons = {
		1: {
			icon: "🤬",
			label: "ממש לא",
		},
		2: {
			icon: "😔",
			label: "לא",
		},
		3: {
			icon: "🤔",
			label: "מתלבט.ת",
		},
		4: {
			icon: "🙂",
			label: "כן...",
		},
		5: {
			icon: "🤩",
			label: "וואו, כן!",
		},
	};

	function IconContainer(props) {
		const { value, ...other } = props;

		return (
			<span {...other} className={`${other.className} `}>
				{customIcons[value].icon}
			</span>
		);
	}

	const displayItemByType = (item) => {
		const question = (item?.question || "").replace("[name]", name);
		switch (item?.feedbackType) {
			case feedbackTypes.boolean:
				return (
					<Box className="wrapQuesAns">
						<Typography component="legend">{question}</Typography>
						<Box sx={{ display: "flex", gap: "5px" }}>
							<Button
								disabled={isDisabled}
								className={value === 1 && "active"}
								color="none"
								type="button"
								onClick={() => !isDisabled && setValue(1)}>
								<span>{item?.yesText}</span>
							</Button>
							<Button
								disabled={isDisabled}
								className={value === 0 && "active"}
								color="none"
								type="button"
								onClick={() => !isDisabled && setValue(0)}>
								<span>{item?.noText}</span>
							</Button>
							{item?.thirdText && (
								<Button
									disabled={isDisabled}
									className={value === 2 && "active"}
									color="none"
									type="button"
									onClick={() => !isDisabled && setValue(2)}>
									<span>{item?.thirdText}</span>
								</Button>
							)}
						</Box>
					</Box>
				);
			case feedbackTypes.rating:
				return (
					<Box className="wrapQuesAns">
						<Typography component="legend">{question}</Typography>
						<Rating
							IconContainerComponent={IconContainer}
							highlightSelectedOnly
							disabled={isDisabled}
							style={{ direction: "ltr" }}
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
						/>
					</Box>
				);

			default:
				return <></>;
		}
	};

	useEffect(() => {
		setValues((prev) => {
			return { ...prev, [item.key]: value };
		});
	}, [value]);

	return <>{displayItemByType(item)}</>;
};
