import "assets/scss/popups/aboutMeAi.scss";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Box } from "@mui/material";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import ProfileService from "services/profile";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function ApproveAiModal() {
	const { closePopup } = usePopups();
	const { uid, isEnteredApprovedAiPopup } = useSelector(getUserProfile);

	const handleApprove = () => {
		if (uid) ProfileService.updateProfile(uid, { approveAi: true });
		closePopup();
	};

	useEffect(() => {
		return () => {
			if (!uid || isEnteredApprovedAiPopup) return;
			return ProfileService.updateProfile(uid, { isEnteredApprovedAiPopup: true });
		};
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div>
				<Button
					color="none"
					style={{ padding: "0", margin: "10px" }}
					type="button"
					onClick={closePopup}>
					<CloseIcon style={{ color: "#2d5086" }} />
				</Button>
			</div>
			<ModalHeader
				style={{
					fontWeight: "700",
					padding: 0,
				}}>{`✨ התאמות חכמות יותר עם בינה מלאכותית! ✨`}</ModalHeader>
			<ModalBody>
				<Box>{`אנחנו משדרגים את חוויית ההכרויות באתר עם טכנולוגיית בינה מלאכותית! 🚀`}</Box>
				<Box>{`איך זה עובד? המערכת מסייעת בניתוח מאפיינים שונים של משתמשים בהתבסס על מידע ושימוש באתר, כדי להציע ניתוחי פרופיל והתאמות מדויקות הרבה יותר. בנוסף, לקבל הסבר יצירתי לסיכויי התאמה שלך עם משתמשים אחרים!`}</Box>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={handleApprove}
					className="btn-rounded littleBtn"
					style={{ fontSize: "20px" }}>
					{`מעולה, תציג לי!`}
				</Button>
				{/* <Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn"
					style={{ fontSize: "14px", border: "none", boxShadow: "none", margin: "0" }}>
					{`בהזדמנות אחרת...`}
				</Button> */}
			</ModalFooter>
		</Modal>
	);
}
