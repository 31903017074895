import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";

import SearchPage from "./SearchPage";
import searchTypes from "consts/typesSearch";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { getUserProfile } from "redux/profile/selectors";
import { getUserGeneral } from "redux/general/selectors";
import {
	getSubscriptionData,
	getSubscriptionsIsSoonExpired,
	getUserSubscriptionType,
} from "redux/subscription/selectors";
import { getIsDesktopWeb, getIsPlatformAndroid, getIsPlatformIOS } from "redux/init/selectors";
import { getPopups } from "redux/popups/selectors";
import { getLoginSteps } from "redux/auth/selectors";

import locationService from "services/locationService";
import ProfileService from "services/profile";
import dynamicPopupsService from "services/dynamicPopups.service";
import subscriptionService from "services/subscription";
import FcmTokenService from "services/fcmToken";
import localStorage from "services/localStorage";

import usePopups from "hooks/usePopups";
import useNavigator from "hooks/useNavigator";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";

import EmailDrawer from "components/drawer/EmailDrawer";
import sourceSaleTypes from "consts/sourceSaleTypes";
import getQuestionFilterByUser from "helpers/getQuestionFilterByUser";

const CheckIfOneWeekPassed = (time, days = 7) => {
	if (!time) return true;
	return moment().isAfter(moment(parseInt(time)).add(days, "days"));
};

export default function MatchesPage() {
	const navigate = useNavigator();
	const uid = useSelector(getLoggedInUserUID);
	const user = useSelector(getUserProfile);
	const userGeneral = useSelector(getUserGeneral);
	const subscription = useSelector(getSubscriptionData);
	const subscriptionsIsSoonExpired = useSelector(getSubscriptionsIsSoonExpired);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isWebPlatform = useSelector(getIsDesktopWeb);
	const subscriptionType = useSelector(getUserSubscriptionType);
	const { popupType = "None" } = useSelector(getPopups);
	const loginSteps = useSelector(getLoginSteps);
	const isUserWithoutSubscription = useShowJoinNowBtn();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [popupInProgress, setPopupInProgress] = useState(false);

	const hasActivePopup = () => popupInProgress || drawerOpen || (popupType && popupType !== "None");

	const {
		showEnteredMatchPopup,
		showDynamicPopup,
		showSubscriptionDealPopup,
		showPhoneNotificationSettingsPopup,
		showApproveShowInTelegramPopup,
		showRateUsPopup,
		showApproveAiPopup,
	} = usePopups();

	useEffect(() => {
		if (!uid || !isPlatformIOS || !isPlatformAndroid) return;

		const checkAndShowNotificationPopup = async () => {
			await locationService.setLocation(uid);
			const phoneTokenDate = localStorage.get("ptt"); //phone token time
			if (
				!user?.allowAppNotification &&
				!hasActivePopup() &&
				CheckIfOneWeekPassed(phoneTokenDate)
			) {
				const userEnteredPopup = user?.enteredNotificationPopupCount || 0;
				if (userEnteredPopup > 3) return;

				const hasPhoneToken = await FcmTokenService.fetchUserToken(user.uid);
				if (!hasPhoneToken && !popupInProgress) {
					setPopupInProgress(true);
					localStorage.set("ptt", Date.now());
					ProfileService.updateProfile(user.uid, {
						enteredNotificationPopupCount: userEnteredPopup + 1,
					});
					setTimeout(showPhoneNotificationSettingsPopup, 1000);
				}
			}
		};

		checkAndShowNotificationPopup();
	}, [uid]);

	useEffect(() => {
		if (!user?.uid || isUserWithoutSubscription === null) return;

		// if (user.uid === "qDWPHc1UgCOmRcrDIbQxeefBW863") {
		//	navigate.redirectWithReload(`speedDating/test111`);
		// }
		// if (!user?.isEnteredMatchPopup) {
		//in the future - get the data from system DB
		// systemService.fetchSiteEntrancePopupData().then((data) => {
		// 	showEnteredMatchPopup(data);
		// 	ProfileService.updateProfile(user.uid, {
		// 		isEnteredMatchPopup: true,
		// 	});`
		// });
		// return setTimeout(() => {
		// 	showEnteredMatchPopup();
		// 	ProfileService.updateProfile(user.uid, {
		// 		isEnteredMatchPopup: true,
		// 	});
		// }, 3000);
		// } else if (!user?.isSendShowInTelegram) {
		// const showTelegramDate = localStorage.get("stpt"); // show telegram popup time
		// if (!showTelegramDate) {
		// 	localStorage.set("stpt", Date.now());
		// 	return showApproveShowInTelegramPopup();
		// } else if (!!CheckIfOneWeekPassed(showTelegramDate)) {
		// 	showApproveShowInTelegramPopup();
		// 	ProfileService.updateProfile(user.uid, {
		// 		isSendShowInTelegram: true,
		// 	});
		// }
		// } else

		if (!user?.isEnteredRateUsPopup) {
			if (!userGeneral?.createDateTime) return;
			const isWeekPassed = moment().diff(moment(userGeneral.createDateTime), "weeks") >= 1;
			if (!isWeekPassed) return;

			const showRateUsDate = localStorage.get("srupt");
			if (!showRateUsDate || CheckIfOneWeekPassed(showRateUsDate, 30)) {
				if (isPlatformIOS || isPlatformAndroid) {
					if (hasActivePopup()) return;
					setPopupInProgress(true);
					localStorage.set("srupt", Date.now());
					return setTimeout(showRateUsPopup, 3000);
				}
			}
		}
		if (!user?.isEnteredApprovedAiPopup && !hasActivePopup()) {
			setPopupInProgress(true);
			return setTimeout(showApproveAiPopup, 3000);
		}

		dynamicPopupsService.getDynemicPopup().then((data) => {
			if (!data) return;
			const filteredPopups = Object.values(data || {}).filter((item) =>
				getQuestionFilterByUser(user, item?.filters || {}, !isUserWithoutSubscription)
			);

			const selectedPopup =
				filteredPopups.length > 0
					? filteredPopups[Math.floor(Math.random() * filteredPopups.length)]
					: null;

			if (selectedPopup && !hasActivePopup()) {
				setPopupInProgress(true);
				dynamicPopupsService.getUserDynemicPopup(user.uid, selectedPopup?.key).then((res) => {
					if (!res) showDynamicPopup(selectedPopup);
				});
			}
		});
	}, [user?.uid, isUserWithoutSubscription]);

	useEffect(() => {
		if (!subscription || !user?.uid || popupType !== "None") return; //|| subscriptionType === subscriptionTypesConst.FREE
		if (subscriptionsIsSoonExpired && !subscription?.renewable) {
			const lastPopupTime = subscription.lastDateShowDealPopup || 0;
			if (lastPopupTime + 86400000 < Date.now() && !hasActivePopup()) {
				setPopupInProgress(true);

				setTimeout(async () => {
					localStorage.set("sourceSale", sourceSaleTypes.matchPagePopup);
					showSubscriptionDealPopup();
					await subscriptionService.updateShowPopup(user.uid, true);
				}, 6000);
			}
		}
	}, [subscription, user?.uid]);

	useEffect(() => {
		if (!drawerOpen && loginSteps?.length === 1 && loginSteps[0] === "Email") {
			const storedLastDateMailPopupOpen = localStorage.get("newMpd");
			if (
				user?.uid &&
				_.isNil(user?.email) &&
				(!storedLastDateMailPopupOpen || CheckIfOneWeekPassed(storedLastDateMailPopupOpen))
			) {
				if (hasActivePopup()) return;
				setPopupInProgress(true);

				localStorage.set("newMpd", moment().valueOf());
				setDrawerOpen(true);
			}
		}
	}, [loginSteps, drawerOpen]);

	return (
		<>
			<SearchPage type={searchTypes.Matches} />
			{drawerOpen && <EmailDrawer />}
		</>
	);
}
