import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const { closePopup } = usePopups();
	const { text, close, textBtn, textBody } = useSelector(getPopups);

	useEffect(() => {
		if (!close) return;
		setTimeout(() => {
			closePopup();
		}, 2500);
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>{text}</ModalHeader>
			{textBody && <ModalBody>{textBody}</ModalBody>}
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn">
					{textBtn || i18next.t("Ok")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
