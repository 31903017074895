import "assets/scss/popups/aboutMeAi.scss";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Box } from "@mui/material";
import usePopups from "hooks/usePopups";
import { useEffect, useState } from "react";
import aiService from "services/ai.service";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import TypingEffect from "helpers/TypingEffect";
import { getPopups } from "redux/popups/selectors";
import { getUserProfile } from "redux/profile/selectors";
import localStorage from "services/localStorage";
import sourceSaleTypes from "consts/sourceSaleTypes";

export default function MatchBetweenUsersAiModal() {
	const { closePopup, showSubscriptionPopup } = usePopups();
	const { uidPartner } = useSelector(getPopups);
	const { gender } = useSelector(getUserProfile);

	const [loading, setLoading] = useState(true);
	const [aiData, setAiData] = useState("");
	const [textLimit, setTextLimit] = useState("");

	const handleClick = () => {
		localStorage.set("sourceSale", sourceSaleTypes.matchAiPopup);
		showSubscriptionPopup();
	};

	useEffect(async () => {
		const res = await aiService.getMatchBetweenUsersAi(uidPartner);
		if (res.data?.success) setAiData(res.data.data);
		else {
			const text = !res?.data?.isSubscription ? (
				<>
					<ModalHeader>{"התאמות חכמות מחכות לך! 🔓"}</ModalHeader>
					<ModalBody>
						<Box>{"השתמשת במקסימום בדיקות ההתאמה היומיות למשתמשים שאינם מנויים."}</Box>
						<Box>
							{`רוצה לפתוח את האפשרות ולגלות מי באמת הכי מתאים לך, עם ניתוחים מעמיקים ותובנות בלעדיות?
							⏪ שדרג את הכרטיס שלך וקבל ניתוחי התאמות נוספים מידי יום!`}
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button
							outline
							size="sm"
							color="primary"
							onClick={handleClick}
							className="btn-rounded active littleBtn"
							style={{ fontSize: "16px" }}>
							{`${gender ? "שדרגי" : "שדרג"} למנוי בלחיצה כאן`}
						</Button>
					</ModalFooter>
				</>
			) : (
				<>
					<ModalHeader>{"הגעת למקסימום... 🚀"}</ModalHeader>
					<ModalBody>
						<Box>
							{
								"השתמשת במקסימום בדיקות ההתאמה שלך – הבינה המלאכותית צריכה לנוח רגע (וגם לתת לך זמן להכיר את ההתאמות שלך 😉)."
							}
						</Box>
						<Box>{`ניפגש שוב מחר עם עוד התאמות חכמות עבורך!`}</Box>
						<ModalFooter>
							<Button
								outline
								size="sm"
								color="primary"
								onClick={closePopup}
								className="btn-rounded active littleBtn"
								style={{ fontSize: "16px" }}>
								{`אחזור שוב!`}
							</Button>
						</ModalFooter>
					</ModalBody>
				</>
			);

			setTextLimit(text);
		}

		setLoading(false);
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog aboutMeAiPopup matchAiPopup">
			<div className="closeBtnAi">
				<Button color="none" type="button" onClick={closePopup}>
					<CloseIcon style={{ color: "#2d5086" }} />
				</Button>
			</div>

			{textLimit ? (
				<>{textLimit}</>
			) : (
				<>
					{!loading && <ModalHeader className="headerContent">{"שליש - Ai"}</ModalHeader>}
					<ModalBody className="aiContent">
						{loading ? (
							<AutoAwesomeIcon className="starsLoading" />
						) : (
							<Box className="innerAiContent">
								<TypingEffect text={aiData} speed={15} />
							</Box>
						)}
					</ModalBody>
				</>
			)}
		</Modal>
	);
}
