import "assets/scss/speedDate/speedDate.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import speedDateStatus from "consts/speedDateStatus";
import useNavigator from "hooks/useNavigator";
import DateList from "components/speedDate/DateList";
import VideoChat from "components/speedDate/VideoChat";
import { Box, CircularProgress } from "@mui/material";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import HeaderVideoStatus from "components/speedDate/videoPage/HeaderVideoStatus";
import moment from "moment";
import DrawerVideoStatus from "components/speedDate/videoPage/DrawerVideoStatus";
import man from "assets/images/speedDate/manVideoHolder.png";
import woman from "assets/images/speedDate/womanVideoHolder.png";
import vdHeart from "assets/images/speedDate/vdHeart.png";
import FooterBtn from "components/speedDate/FooterBtn";
import breakSpeed from "assets/images/speedDate/breakSpeed.png";
import manBreakSpeed from "assets/images/speedDate/manBreakSpeed.png";
import { getIsDesktopWeb } from "redux/init/selectors";

export default function SpeedDatingPage() {
	const navigate = useNavigator();
	let { urlName } = useParams();
	const { showJaaSMeetingPopup, showFeedbackUserPopup } = usePopups();

	const currentUser = useSelector(getUserProfile) || {};
	const [listDates, setListDates] = useState([]);
	const [nextDate, setNextDate] = useState(null);
	const [currentDate, setCurrentDate] = useState(null);
	const [speedData, setSpeedDate] = useState({});
	const [loading, setLoading] = useState(false);
	const [eventEndTime, setEventEndTime] = useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [showStatusBar, setShowStatusBar] = useState(false);
	const [remainingTime, setRemainingTime] = useState();
	const [dateDuration, setDateDuration] = useState(10);
	const [feedbackUser, setFeedbackUser] = useState("");

	const closeVideoDate = () => {
		setCurrentDate(null);
		setTimeout(() => {
			getCurrentAndNextTime(listDates);
		}, 1);
	};
	const toggleDrawerParent = (open = true) => {
		setIsDrawerOpen(open);
	};
	const handleFinishDate = () => {
		getCurrentAndNextTime(listDates);
	};

	const navigateAccountVideoDate = () => {
		return navigate.navigateToAccountVideoDate();
	};
	useEffect(() => {
		if (!urlName) return navigateAccountVideoDate();
		speedDatingService.getSpeedDateInfoByUrlName(urlName).then((data) => {
			if (!data) return navigateAccountVideoDate();
			const sData = Object.values(data || {})[0];

			if (
				!sData ||
				sData?.status !== speedDateStatus.matched ||
				!moment(sData?.eventDate).isSame(moment(), "day") //its not today
			)
				return navigateAccountVideoDate();

			const eventData = sData?.eventDate;
			const eventEndTime = moment(eventData).add(2, "hours"); //1 hours after the start date

			if (eventEndTime < moment().valueOf()) {
				setEventEndTime(true);
				return navigate.navigateToAccountVideoDate({ urlName: urlName });
			}
			setSpeedDate(sData);
		});
	}, []);

	const getCurrentAndNextTime = (list = []) => {
		const currentTime = moment();
		let current = null;
		let next = null;

		for (let i = 0; i < list.length; i++) {
			const dateTime = moment(list[i].time, "HH:mm").set({ second: 0 });
			const endDateTime = moment(list[i].endDateTime).set({ second: 0 });

			const isWithinTimeRange = currentTime.isBetween(dateTime, endDateTime, null, "[]");
			if (isWithinTimeRange) {
				current = list[i];

				// Find the next date
				for (let j = i + 1; j < list.length; j++) {
					if (list[j]?.uidMatch) {
						next = list[j];
						break;
					}
				}
				break;
			} else {
				const firstDateTime = moment(list[0].time, "HH:mm").set({ second: 0 });
				if (firstDateTime.isAfter(moment())) {
					next = list[0];
				}
			}
		}

		if (feedbackUser && current && current?.uidMatch && current?.uidMatch !== feedbackUser)
			setFeedbackUser("");

		setCurrentDate(current);
		setNextDate(next);
	};

	useEffect(() => {
		if (listDates?.length) {
			setShowStatusBar(true);

			getCurrentAndNextTime(listDates);

			const interval = setInterval(() => {
				getCurrentAndNextTime(listDates);
			}, 5000);

			return () => clearInterval(interval);
		}
	}, [listDates]);
	useEffect(() => {
		if (!!feedbackUser && !currentDate?.uidMatch) {
			showFeedbackUserPopup({
				uidMatch: feedbackUser,
				currentDate: listDates?.find((user) => user?.uidMatch === feedbackUser),
				speedData,
				uid: currentUser?.uid,
			});
			setFeedbackUser("");
		}
	}, [feedbackUser, currentDate]);

	return (
		<div className="speedDatePageWrapper">
			{(!!speedData?.key || !!eventEndTime) && (
				<>
					{!showStatusBar ? (
						<img className="vdHeart" src={vdHeart} />
					) : (
						<HeaderVideoStatus
							toggleDrawer={toggleDrawerParent}
							eventDate={speedData?.eventDate}
							currentDate={currentDate}
							nextDate={nextDate}
							eventEndTime={eventEndTime}
							remainingTime={remainingTime}
							closeVideoDate={closeVideoDate}
						/>
					)}
					{!!currentDate && currentDate?.uidMatch ? (
						<VideoChat
							currentDate={currentDate}
							handleFinishDate={handleFinishDate}
							setRemainingTime={setRemainingTime}
							closeVideoDate={closeVideoDate}
							dateDuration={dateDuration}
							setFeedbackUser={setFeedbackUser}
							speedData={speedData}></VideoChat>
					) : (
						<PlaceholderVideo
							showJaaSMeetingPopup={showJaaSMeetingPopup}
							nextDate={nextDate}
							speedData={speedData}
							navigateAccountVideoDate={navigateAccountVideoDate}
							gender={currentUser?.gender}
							currentUser={currentUser}
							toggleDrawer={toggleDrawerParent}
							feedbackUser={feedbackUser}
						/>
					)}
				</>
			)}
			{!!speedData?.key && !!currentUser?.uid && (
				<>
					<Box
						className="bottomDrawer"
						style={{ background: !(!!currentDate && currentDate?.name) && "#fff" }}>
						<DrawerVideoStatus
							speedData={speedData}
							currentDate={currentDate}
							toggleDrawerParent={toggleDrawerParent}
							isDrawerOpen={isDrawerOpen}
							setListDates={setListDates}
							dateDuration={dateDuration}
							setDateDuration={setDateDuration}
							closeVideoDate={closeVideoDate}
						/>
					</Box>
				</>
			)}
		</div>
	);
}

//check camera and mic

{
	/* {!!currentUser && <div>{currentUser.name}</div>}
					{!!loading ? (
						<CircularProgress size={30} className="text-primary" style={{ margin: "10px 0" }} />
					) : (
						<Button
							style={{ margin: "10px 0", padding: 0 }}
							color="none"
							type="button"
							onClick={openTestJaaSMeeting}>
							<span>{"openTestJaaSMeeting"}</span>
						</Button>
					)} */
}

function PlaceholderVideo({
	nextDate,
	speedData,
	navigateAccountVideoDate,
	gender,
	toggleDrawer = () => {},
	showJaaSMeetingPopup = () => {},
	currentUser,
}) {
	const isWebPlatform = useSelector(getIsDesktopWeb);
	const [showPlaceHolders, setShowPlaceHolders] = useState(false);
	const [loading, setLoading] = useState(false);

	const openTestJaaSMeeting = () => {
		if (!speedData?.key) return;
		setLoading(true);
		speedDatingService.getTestRoom(speedData.key).then((data) => {
			console.log(data);
			if (data.data?.success) {
				showJaaSMeetingPopup({ ...data.data, ...currentUser });
			}
			setLoading(false);
		});
	};

	const openAssistantWhatsapp = (e) => {
		e.preventDefault();
		return window.open(
			"https://wa.me/97286519660?text=היי+אשמח+לעזרה+בוידאו+דייט:+הבעיה+שנתקלתי+בה+היא+ש",
			"_blank"
		);
	};
	const getUList = () => {
		return (
			<ul className="instructions-list">
				<b>{"לבינתיים, כמה הנחיות קצרצרות... "}</b>
				<li>
					{"מיד בכניסה לפגישה - חשוב לאפשר לאפליקציה לגשת לוידאו ולאודיו במכשיר שלך."}

					{/* {!!loading ? (
						<CircularProgress size={30} className="text-primary" style={{ margin: "0 5px" }} />
					) : (
						<Button
							style={{
								margin: "0 5px",
								padding: "0",
								lineHeight: 1,
								color: "#2d5086",
								fontSize: "18px",
							}}
							type="button"
							color="none"
							className="assistantWhatsapp"
							onClick={openTestJaaSMeeting}>
							<u>{`לבדיקה ${gender ? "לחצי" : "לחץ"} כאן`}</u>
						</Button>
					)} */}
				</li>

				<li>{"לאחר הכניסה לפגישה יש להמתין בסבלנות לכניסת הצד השני."}</li>
				<li>
					<b>{"על מנת לראות את עצמך במהלך הדייט"}</b>
					{" - יש ללחוץ על החץ שמופיע באמצע מסך הוידאו מימין, או ללחוץ לחיצה כפולה על המסך."}
				</li>
				<li>{"עם סיום הפגישה יש למלא משוב על הדייט שהתקיים."}</li>
				<li>
					<span>{"לעזרה "}</span>
					<b>{"במקרים דחופים"}</b>
					{` טרום האירוע ובמהלכו, ניתן לשלוח הודעת וואטסאפ עם פירוט הבעיה בלחיצה כאן ונשתדל לעזור.`}
					<Button
						style={{ margin: "0 5px", padding: "3px 10px", borderRadius: "20px", lineHeight: 1 }}
						type="button"
						className="assistantWhatsapp"
						onClick={openAssistantWhatsapp}>
						<span>{"שלח הודעה אלינו בוואטסאפ"}</span>
					</Button>
				</li>

				<b>{"בהצלחה רבה!"}</b>
			</ul>
		);
	};
	const getSpeedIsOverPlaceholder = () => (
		<Box className="placeholderVideo">
			<Box>
				<h3 style={{ fontWeight: "700", textAlign: "center" }}>
					{"עוד אירוע וידאו דייט הגיע לסיומו 🥰"}
				</h3>
				<h3 style={{ textAlign: "center" }}>{"בקרוב ממש נעדכן על התוצאות... "}</h3>
				<FooterBtn handleClick={navigateAccountVideoDate} text={"להמשך גלישה באתר"} />
			</Box>
		</Box>
	);

	const getSpeedInBreakOrBeforeStart = () => {
		if (moment().isBefore(moment(speedData?.eventDate))) {
			return (
				<Box className="newPlaceholder">
					<Box className="wrapWelcome">
						<h3>{gender ? "ברוכה הבאה!" : "ברוך הבא!"}</h3>
						<h2>{`הפגישה הקרובה שלך תהיה ב ${
							nextDate?.time || moment(speedData?.eventDate)?.format("HH:mm")
						}`}</h2>
						{getUList()}
						<img src={breakSpeed} />
					</Box>
				</Box>
			);
		} else {
			return (
				<Box className="newPlaceholder">
					<Box className="wrapWelcome breakManImg">
						<h3 style={{ textAlign: "center" }}>
							{"ממתין לדייט הבא 🥰"}

							{isWebPlatform && (
								<h3 style={{ fontSize: "1.2rem" }}>
									{"על מנת למלא משוב על הדייט - "}
									<u onClick={toggleDrawer} style={{ cursor: "pointer" }}>
										{"יש ללחוץ כאן "}
									</u>
								</h3>
							)}
						</h3>
						{getUList()}
						<img src={manBreakSpeed} />
					</Box>
				</Box>
			);
		}
		return (
			<Box className="placeholderVideo">
				<img src={!gender ? woman : man} />
				<Box className="smallImg" style={{ padding: gender ? "10px 20px" : "10px" }}>
					<img src={gender ? woman : man} />
				</Box>

				{/* {!!loading ? (
					<CircularProgress
						size={30}
						className="testVideoBtn text-primary"
						style={{ margin: "10px 0" }}
					/>
				) : (
					<Button
						style={{ margin: "10px 0", padding: 0, color: "#2d5086" }}
						color="none"
						type="button"
						className="testVideoBtn"
						onClick={openTestJaaSMeeting}>
						<span>{"כדי לוודא שהכל עובד חלק - מומלץ לבדוק את הוידאו"}</span>
					</Button>
				)} */}
			</Box>
		);
	};

	useEffect(() => {
		if (!!nextDate) setShowPlaceHolders(true);
		setTimeout(() => {
			if (!showPlaceHolders && !nextDate) setShowPlaceHolders(true);
		}, 2000);
	}, []);

	return (
		<>
			{showPlaceHolders && (
				<>
					{!nextDate && moment().isAfter(moment(speedData?.eventDate))
						? getSpeedIsOverPlaceholder()
						: getSpeedInBreakOrBeforeStart()}
				</>
			)}
		</>
	);
}
