import "assets/scss/popups/aboutMeAi.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box } from "@mui/material";
import usePopups from "hooks/usePopups";
import { useEffect, useState } from "react";

import aiService from "services/ai.service";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TypingEffect from "helpers/TypingEffect";

export default function AboutMeAiModal() {
	const { closePopup } = usePopups();
	const [loading, setLoading] = useState(true);
	const [aiData, setAiData] = useState({});
	const profile = useSelector(getUserProfile);

	useEffect(async () => {
		const res = await aiService.getAboutMeAi();
		if (res.data?.success) setAiData(res.data.data);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	const shareAboutMeAi = () => {
		if (!profile?.uid) return;

		const formattedAiData = aiData.replaceAll(/\n/g, "%0A");
		const domain = process.env.REACT_APP_DOMAIN;
		const userUrl = `${domain}/user/${profile?.uid}?f=10514538`;

		const text = `${formattedAiData}%0A%0A${userUrl}`;
		const shareUrl = `https://api.whatsapp.com/send?text=${text}`;

		window.open(shareUrl);
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog aboutMeAiPopup">
			<div className="closeBtnAi">
				<Button color="none" type="button" onClick={closePopup}>
					<CloseIcon style={{ color: "#2d5086" }} />
				</Button>
			</div>

			<ModalHeader className="headerContent">
				<Box onClick={shareAboutMeAi} className="shareAi">
					<WhatsAppIcon />
					<span>{profile?.gender ? "שתפי" : "שתף"}</span>
				</Box>

				{"שליש - Ai"}
			</ModalHeader>
			<ModalBody className="aiContent">
				{loading ? (
					<AutoAwesomeIcon className="starsLoading" />
				) : (
					<Box className="innerAiContent">
						<TypingEffect text={aiData} speed={15} />
					</Box>
				)}
			</ModalBody>
		</Modal>
	);
}
