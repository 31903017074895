import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

import PromotionStatus from "consts/promotionStatus";
import httpsCallable from "./httpsCallable";
import localStorage from "./localStorage";

class PromotionService {
	BASE_REF = "promotion";
	PROMOTIONS_SALE_PLANS = "promotions_sale_plans";

	async fetch(uid) {
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("expiredIn")
					.startAt(Date.now())
					.once("value")
			).val() || {}
		);
	}

	listenPromotionAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("timeStamp")
				.startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenPromotionUpdates(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	updateStatusLikeOrDislike(uid, colleagueUid) {
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child(colleagueUid)
			.child("status")
			.set(PromotionStatus.LikeOrDisLike);
	}

	virtualDeletePromotion(colleagueUid) {
		const uid = firebase.auth().currentUser?.uid;
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child(colleagueUid)
			.child("deleted")
			.set(true);
	}
	async getActivePromotionsSalePlan() {
		const result = await httpsCallable.call("getActivePromotionsSalePlan");
		if (result.data?.success) {
			return result.data?.plan || null;
		}
		return null;
	}
	getSourceSale() {
		return localStorage.get("sourceSale") || null;
	}
	async getPromotionPay(planId) {
		const result = await httpsCallable.call("getPromotionPay", {
			planId,
			sourceSale: this.getSourceSale(),
		});
		return result;
	}
	async getCanBuyPromotion(planId) {
		const result = await httpsCallable.call("getCanBuyPromotion", { planId });
		return result;
	}
}

export default new PromotionService();
